import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { GtmModule } from 'ngx-gtm';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTwitter, faLinkedin, faFacebook, faFacebookSquare, faYoutube, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import '@progress/kendo-angular-intl/locales/es/all';
import { MatVideoModule } from 'mat-video';
import 'hammerjs';
import { gsap, ScrollTrigger, ScrollToPlugin, Draggable, CSSRulePlugin } from "gsap/all";
import { NgxCaptchaModule } from 'ngx-captcha';
import { AnimateModule } from '@wizdm/animate';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { NgxPaginationModule } from 'ngx-pagination';
//Helpers
import { SafePipe, SplitPipe } from './helpers/safe-pipe';
import { AppearDirective } from './helpers/appear';
//Routing
import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
//Services
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { ThemeManager } from './services/theme-manager';
import { LocalStoreManager } from './services/local-store-manager.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { DropdownsService } from './services/dropdowns.service';
import { DropdownsEndpoint } from './services/dropdowns-endpoint.service';
import { SignInService } from './services/sign-in.service';
import { SignInEndpoint } from './services/sign-in-endpoint.service';
import { ProfileService } from './services/profile.service';
import { ProfileEndpoint } from './services/profile-endpoint.service';
import { SearchService } from './services/search.service';
import { SearchEndpoint } from './services/search-endpoint.service';
import { CmsEndpoint } from './services/cms/cms-endpoint.service';
import { CmsHomeService } from './services/cms/cms.home.service';
import { CmsOfertaService } from './services/cms/cms.oferta.service';
import { CmsPlanComercialService } from './services/cms/cms.plan.comercial.service';
import { CmsFuturecastService } from './services/cms/cms.futurecast.service';

//Directives
import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { NumericDirective } from './directives/numeric.directive';
import { AlphabetDirective } from './directives/alphabet.directive';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { NumericandspacesDirective } from './directives/numericandspaces.directive';
//Pipes
import { GroupByPipe } from './pipes/group-by.pipe';
//Components
import { AppComponent } from './components/app.component';
//Website Common
import { WebsiteFooterComponent } from './components/Website/common/website-footer/website-footer.component';
import { HeaderImageComponent } from './components/Website/common/header-image/header-image.component';
import { WebsiteMenuComponent } from './components/Website/common/website-menu/website-menu.component';
import { VerMasComponent } from './components/Website/common/general/ver-mas/vermas.component';
import { TarjetaComponent } from './components/Website/common/tarjeta/tarjeta.component';
import { TextoComponent } from './components/Website/common/texto/texto.component';
import { TarjetaProgramaComponent } from './components/Website/common/tarjeta-programa/tarjeta-programa.component';
import { TarjetaFuturecastComponent } from './components/Website/common/tarjeta-futurecast/tarjeta-futurecast.component';
import { BlocksComponent } from './components/Website/common/blocks/blocks.component';
import { EnConstruccionComponent } from './components/Website/common/en-construccion/en-construccion.component';
import { TarjetaSimpleComponent } from './components/Website/common/tarjeta-simple/tarjeta-simple.component';
import { TarjetaContactoComponent } from './components/Website/common/tarjeta-contacto/tarjeta-contacto.component';
import { TarjetaEventoComponent } from './components/Website/common/tarjeta-evento/tarjeta-evento.component';
import { ResultadosBuscadorComponent } from './components/Website/common/resultados-buscador/resultados-buscador.component';
import { TarjetaBuscadorComponent } from './components/Website/common/tarjeta-buscador/tarjeta-buscador.component';
import { HeaderImageNewComponent } from './components/Website/common/header-image-new/header-image-new.component';
//Website Components
import { WebLayoutComponent } from './components/Website/web-layout/web-layout.component';
import { PlanComercialComponent } from './components/Website/plan-comercial/plan-comercial.component';
import { ContactoComponent } from './components/Website/contacto/contacto.component';
import { NuestraOfertaComponent } from './components/Website/nuestra-oferta/nuestra-oferta.component';
import { FuturecastComponent } from './components/Website/futurecast/futurecast.component';
import { AcercaDeComponent } from './components/Website/acerca-de/acerca-de.component';
import { EventosComponent } from './components/Website/eventos/eventos.component';
import { PlataformaComponent } from './components/Website/plataforma/plataforma.component';
import { HomeComponent } from './components/Website/home/home.component';
import { PilaresComponent } from './components/Website/pilares/pilares.component';
import { GenerosComponent } from './components/Website/generos/generos.component';
import { CalendarioComponent } from './components/Website/calendario/calendario.component';
import { PaquetesDetalleComponent } from './components/Website/paquetes-detalle/paquetes-detalle.component';
import { CartaEditorialComponent } from './components/Website/carta-editorial/carta-editorial.component';
import { AvisoPrivacidadComponent } from './components/Website/aviso-privacidad/aviso-privacidad.component';
import { DetalleFuturecastComponent } from './components/Website/detalle-futurecast/detalle-futurecast.component';
import { DetalleProgramaComponent } from './components/Website/detalle-programa/detalle-programa.component';
import { CanalesRegionalComponent } from './components/Website/canales-regional/canales-regional.component';
import { DetalleEventoComponent } from './components/Website/detalle-evento/detalle-evento.component';
import { UniversoTelevisaComponent } from './components/Website/universo-televisa/universo-televisa.component';
import { PaquetesComponent } from './components/Website/paquetes/paquetes.component';
import { ProductosComponent } from './components/Website/productos/productos.component';
import { TarjetaProductoComponent } from './components/Website/common/tarjeta-producto/tarjeta-producto.component';
import { CarruselProgramasComponent } from './components/Website/common/carrusel-programas/carrusel-programas.component';
import { CustomBreadcrumbsComponent } from './components/Website/common/custom-breadcrumbs/custom-breadcrumbs.component';
import { FormatosdigitalesComponent } from './components/Website/formatosdigitales/formatosdigitales.component';

//Register
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable, CSSRulePlugin); 
registerLocaleData(localeEsMx, 'es-MX');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    OAuthModule.forRoot(),
    ReactiveFormsModule,
    FontAwesomeModule,
    MatVideoModule,
    CarouselModule,
    NgxCaptchaModule,
    AnimateModule,
    GtmModule.forRoot({
      gtmId: 'GTM-MBZSJGN', //PROD e INTERNO = 'GTM-PJSD7PS' //QA y DEV ='GTM-MBZSJGN'
      isEnabled: false //DEV e INTERNO false // PROD y QA true
    }),
    RouterModule,
    BreadcrumbsModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    SchedulerModule,
    NgxPaginationModule
  ],
  declarations: [
    AppComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    GroupByPipe,
    NumericDirective,
    AlphabetDirective,
    AlphanumericDirective,
    NumericandspacesDirective,
    SafePipe,
    SplitPipe,
    AppearDirective,
    WebLayoutComponent,
    WebsiteFooterComponent,
    WebsiteMenuComponent,
    HeaderImageComponent,
    VerMasComponent,
    PlanComercialComponent,
    ContactoComponent,
    NuestraOfertaComponent,
    FuturecastComponent,
    AcercaDeComponent,
    EventosComponent,
    PlataformaComponent,
    PilaresComponent,
    GenerosComponent,
    CalendarioComponent,
    TarjetaComponent,
    CartaEditorialComponent,
    PaquetesDetalleComponent,
    TextoComponent,
    AvisoPrivacidadComponent,
    HomeComponent,
    EnConstruccionComponent,
    TarjetaProgramaComponent,
    TarjetaFuturecastComponent,
    DetalleFuturecastComponent,
    DetalleProgramaComponent,
    BlocksComponent,
    TarjetaSimpleComponent,
    TarjetaContactoComponent,
    UniversoTelevisaComponent,
    CanalesRegionalComponent,
    DetalleEventoComponent,
    TarjetaEventoComponent,
    ResultadosBuscadorComponent,
    TarjetaBuscadorComponent,
    PaquetesComponent,
    HeaderImageNewComponent,
    ProductosComponent,
    TarjetaProductoComponent,
    CarruselProgramasComponent,
    CustomBreadcrumbsComponent,
    FormatosdigitalesComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    OidcHelperService,
    DropdownsService,
    DropdownsEndpoint,
    SignInEndpoint,
    SignInService,
    ProfileEndpoint,
    ProfileService,
    SearchEndpoint,
    SearchService,
    CmsEndpoint,
    CmsHomeService,
    CmsPlanComercialService,
    CmsOfertaService,
    CmsFuturecastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTwitter, faLinkedin, faFacebook, faFacebookSquare, faYoutube, faInstagramSquare);
  }
}
